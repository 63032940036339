<template>
  <v-app
    standalone
    color="red"
  >
    <v-navigation-drawer
      v-model="drawer.state"
      :mini-variant.sync="drawer.mini"
      app
      width="300"
      :hide-overlay.sync="drawer.hideOverlay"
      mini-variant-width="60"
      :temporary.sync="drawer.temporary"
    >
      <v-toolbar
        elevation="0"
      >
        <v-btn
          class="m-0"
          color="white"
          icon
          @click.stop="toogleDrawer()"
        >
          <v-icon
            v-if="!isDrawerOpen"
            class="primary--text"
          >
            menu
          </v-icon>
          <v-icon
            v-if="isDrawerOpen"
            class="primary--text"
          >
            close
          </v-icon>
        </v-btn>
        <v-toolbar-title>
          <div class="bl-logo-menu" />
        </v-toolbar-title>
      </v-toolbar>
      <v-list
        class="user-info toolbar"
        color="primary"
        dense
        elevation="2"
      >
        <v-list-item>
          <v-list-item-action>
            <v-avatar
              size="35"
              color="white"
            >
              <v-icon color="primary">
                person
              </v-icon>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <span class="white--text">{{ user.email }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list dense>
        <template v-for="(menuPosition, i) in menuPositions">
          <v-divider
            v-if="menuPosition.isDivider"
            :key="`${menuPosition.name}-divider`"
          />
          <v-list-item
            v-if="menuPosition.show"
            :key="menuPosition.name"
            :to="{ name: menuPosition.url }"
            @click="menuCurrentPosition = i"
          >
            <v-list-item-action>
              <v-icon>
                {{ menuPosition.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content :class="`menu-${i}`">
              <v-list-item-title>
                {{ menuPosition.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list dense>
        <template v-for="(menuPosition, i) in menuProfilePositions">
          <template v-if="menuPosition.show">
            <v-divider
              v-if="menuPosition.isDivider"
              :key="`${menuPosition.name}-divider`"
              class="mb-8"
            />
            <v-list-item
              v-if="!menuPosition.isDivider"
              :key="menuPosition.name"
              @click="openProfileMenuItem(menuPosition)"
            >
              <v-list-item-action>
                <v-icon>
                  {{ menuPosition.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content :class="`profile-menu-${i}`">
                <v-list-item-title>
                  {{ menuPosition.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ menuPosition.subName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      tabs
      color="white"
      class="primary-underline"
    >
      <v-btn
        v-if="!user.termsOfUseAccepted"
        class="m-0"
        icon
        @click.stop="toogleDrawer()"
      >
        <v-icon
          v-if="!isDrawerOpen"
          color="primary"
        >
          menu
        </v-icon>
        <v-icon
          v-if="isDrawerOpen"
          color="primary"
        >
          close
        </v-icon>
      </v-btn>

      <div class="hidden-xs-only pl-6 bl-logo-menu" />
      <v-spacer />
      <v-toolbar-title class="primary--text">
        <h3>{{ menuPositions[menuCurrentPosition].name }}</h3>
      </v-toolbar-title>
      <v-spacer />
      <div
        class="toolbar-summary"
      >
        <v-btn
          v-if="accountBalance !== null"
          color="primary"
          text
          :to="{ name: 'transactions' }"
          @click.native="menuCurrentPosition = 1"
        >
          <div class="toolbar-summary-label hidden-sm-and-down">
            {{ $t('your_balance') }}
          </div>
          <v-icon>account_balance_wallet</v-icon>
          <div class="toolbar-summary-amount">
            {{ accountBalance }} {{ balanceCurrency }}
          </div>
        </v-btn>
      </div>
      <div
        class="toolbar-summary px-2"
      >
        <v-menu
          offset-y
          :nudge-top="-10"
          transition="slide-y-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <div>
                <country-flag
                  :country="langToCountry(lang)"
                  size='normal'
                  class="country-flag"
                />
              </div>
            </v-btn>
          </template>
          <v-list class="profile-menu">
            <v-list-item
              v-for="(language, i) in languages"
              :key="i"
              @click="setLanguage(language.locale)"
            >
              <v-list-item-icon>
                <country-flag
                  :country="language.country_code"
                  size='normal'
                  class="country-flag"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title color="primary">
                  {{ language.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
    <v-main>
      <v-dialog
        v-model="applicationError"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t('payment_error') }}
          </v-card-title>
          <v-card-text>{{ $t('applicationError.message') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="applicationError = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view />
    </v-main>
    <v-footer
      app
      class="pr-4 pl-4"
      color="primary"
      style="color: white"
    >
      <div class="footer-wrap">
        <div class="footer-left">
          © I2M Sp. z o.o. {{ currentYear }}
        </div>
        <div class="footer-right">
          <a
            :href="facebookLink"
            class="footer-link"
            target="_blank"
          ><img src="~@assetsCommon/images/fb_icon.png"></a>
        </div>
      </div>
    </v-footer>
    <!-- <message-modal ref="messageModal" /> -->
    <terms-of-use-modal
      ref="termsOfUse"
    />
    <settings-modal
      ref="settingsModal"
    />
    <support-modal
      ref="supportModal"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="toastTimeout"
      :bottom="true"
      :multi-line="true"
      :color="snackbarColor"
      style="z-index: 10000"
    >
      {{ snackbarText }}

      <v-btn
        text
        class="float-right mt-2"
        @click.native="snackbar = false"
      >
        {{ $t('actions.close') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@plugins/axios';
import common from '@components/financial/common.js';
import SettingsModal from '@components/modals/SettingsModal.vue';
import SupportModal from '@components/modals/SupportModal.vue';
import TermsOfUseModal from '@components/modals/TermsOfUseModal.vue';

export default {
  name: 'MainComponent',
  components: {
    SupportModal,
    SettingsModal,
    TermsOfUseModal,
  },
  data() {
    return {
      drawer: {
        hideOverlay: true,
        state: false,
        mini: true,
        temporary: false,
      },
      menuCurrentPosition: 0,
      accountBalance: null,
      balanceCurrency: null,
      lang: 'pl',
      languages: [],
      logout: false,
      applicationError: false,
      snackbarColor: null,
      snackbarText: null,
      snackbar: null,
      toastTimeout: common.toastTimeout,
    };
  },
  computed: {
    menuProfilePositions() {
      return [
        {
          name: this.$i18n.t('invoice_data'),
          icon: 'business',
          modal: 'settingsModal',
          show: !this.isFleetMember,
        },
        {
          name: this.$i18n.t('menu.terms_of_use'),
          icon: 'assignment',
          location: this.termsOfUseLink,
          target: '_blank',
          show: true,
        },
        {
          name: this.$i18n.t('menu.report_problem'),
          icon: 'warning',
          modal: 'supportModal',
          show: true,
        },
        { isDivider: true },
        {
          name: this.$i18n.t('logout'),
          icon: 'lock_open',
          callback: 'logoutFromApplication',
          showOnTabs: false,
          show: true,
        },
      ];
    },
    menuPositions() {
      return [
        {
          id: 0,
          name: this.$i18n.t('menu.invoices'),
          icon: 'description',
          url: 'invoices',
          show: true,
        },
        {
          id: 1,
          name: this.$i18n.t('menu.transactions'),
          icon: 'attach_money',
          url: 'transactions',
          show: true,
        },
        {
          id: 2,
          name: this.$i18n.t('menu.users'),
          icon: 'supervisor_account',
          url: 'users',
          show: this.isFleetManager,
        },
        {
          id: 3,
          name: this.$i18n.t('license_plate'),
          icon: 'directions_car',
          url: 'license_plates',
          show: true,
        },
        { isDivider: true },
      ];
    },
    isDrawerOpen() {
      return ((this.isMobile) ? this.drawer.state : !this.drawer.mini);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    ...mapGetters({
      facebookLink: 'branding/facebook_link',
      isLogged: 'auth/isLoggedIn',
      user: 'auth/getUser',
      isFleetManager: 'auth/isFleetManager',
      appName: 'branding/appName',
      isFleetMember: 'auth/isFleetMember',
      termsOfUseLink: 'branding/terms_link',
      userLocale: 'auth/userLocale',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(target) {
        const menuName = target.name ? this.$t(`menu.${target.name}`) : '';
        document.title = `${menuName} | ${this.appName}`;
      },
    },
  },
  mounted() {
    this.initDrawer();

    let menuPositionName = this.$router.currentRoute.name;
    if (menuPositionName === undefined) {
      menuPositionName = 'transactions';
    }
    const menuPositionFromRouter = this.menuPositions.find(
      (menuPosition) => menuPosition.url === menuPositionName,
    ).id;
    if (menuPositionFromRouter) {
      this.menuCurrentPosition = menuPositionFromRouter;
    }
    this.getBalanceData();
    this.getLanguagesData();
    this.$eventHub.$on('application-refresh-balance', this.getBalanceData);
    this.$eventHub.$on('show_application_snackbar', this.showToast);
    this.lang = this.userLocale;
  },
  beforeDestroy() {
    this.$eventHub.$off('application-refresh-balance');
    this.$eventHub.$off('show_application_snackbar');
  },
  methods: {
    langToCountry(langLocale) {
      if (langLocale === 'en') {
        return 'us';
      }

      if (langLocale === 'sv') {
        return 'se';
      }

      return langLocale;
    },
    initDrawer() {
      if (this.isMobile) {
        this.drawer.mini = false;
        this.drawer.state = false;
        this.drawer.temporary = true;
        this.drawer.hideOverlay = false;
      } else {
        this.drawer.state = true;
        this.drawer.mini = false;
        this.drawer.temporary = false;
        this.drawer.hideOverlay = true;
      }
    },
    toogleDrawer() {
      if (this.isMobile) {
        this.drawer.hideOverlay = false;
        this.drawer.mini = false;
        this.drawer.state = !this.drawer.state;
      } else {
        this.drawer.hideOverlay = true;
        this.drawer.mini = !this.drawer.mini;
        this.drawer.state = true;
      }
    },
    myCustomPreviousStepCallback(step) {
      if (step === 5) {
        this.mini = false;
      }
    },
    myCustomNextStepCallback(step) {
      if (step === 4) {
        this.mini = true;
      }
    },
    openProfileMenuItem(item) {
      if (item.url) {
        this.$router.replace(item.url);
      } else if (item.modal) {
        this.$refs[item.modal].dialog = true;
        this.mini = true;
      } else if (item.callback) {
        this[item.callback]();
      } else if (item.location) {
        if (item.target) {
          window.open(item.location, item.target).focus();
        } else {
          window.location.href = item.location;
        }
        this.mini = true;
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      this.lang = lang;
      axios.patch(
        '/api/users/language',
        {
          language: this.lang,
        },
      );
      window.location.reload();
    },
    getLanguagesData() {
      axios.get('/api/lists/languages')
        .then((response) => {
          if (response.data) {
            this.languages = response.data;
          }
        });
    },
    getBalanceData() {
      axios.get('/api/users/cards')
        .then((response) => {
          if (response.data) {
            // this.accountBalance = 0.0;
            // this.balanceCurrency = '';
            this.balanceCurrency = response.data.trans[0].currency.symbol;
            this.accountBalance = response.data.transSum;
          }
        });
    },
    logoutFromApplication() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login');
      });
    },
    showToast(color, message) {
      this.snackbarColor = color;
      this.snackbarText = message;
      this.snackbar = true;
    },
  },

};
</script>

<style>
.country-flag {
  outline: 1px solid #8a7b7b;
}
</style>
