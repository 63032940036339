<template>
  <v-layout
    row
    justify-center
  >
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-btn
            icon
            dark
            @click.native="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon>business</v-icon>
            {{ $t('invoice_data') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              :disabled="loading"
              @click="submit"
            >
              {{ $t('navigation.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-progress-linear
          class="no-margin"
          :indeterminate="loading"
        />
        <v-card-text>
          <form>
            <v-radio-group
              v-model="type"
              required
            >
              <v-radio
                v-for="radio in typeRadios"
                :key="radio.value"
                :label="radio.name"
                :value="radio.value"
              />
            </v-radio-group>
            <v-text-field
              v-model="phone"
              v-validate="'max:128'"
              :label="$t('support.phone')"
              :counter="128"
              :error-messages="errors.collect('phone')"
              data-vv-name="email"
              :data-vv-as="$t('support.phone')"
              :disabled="loading"
            />
            <v-textarea
              v-model="problem"
              v-validate="'required|max:128'"
              :label="$t('report_problem_description')"
              :counter="128"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              :data-vv-as="$t('report_problem_description')"
              :disabled="loading"
              required
            />
            <div class="text-sm-start">
              <v-btn
                color="primary"
                @click="close"
              >
                {{ $t('update_cancel') }}
              </v-btn>
              <v-btn
                :disabled="loading"
                @click="submit"
              >
                {{ $t('navigation.save') }}
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import axios from '@plugins/axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      typeRadios: [
        {
          name: this.$t('report_problem_other'),
          value: 'SupportType.problemWithPayment',
        },
        {
          name: this.$t('report_problem_carwash'),
          value: 'SupportType.problemWithCarwash',
        },
        {
          name: this.$t('report_problem_qr'),
          value: 'SupportType.problemWithQrCode',
        },
        {
          name: this.$t('report_problem_invoice'),
          value: 'SupportType.problemWithInvoice',
        },
        {
          name: this.$t('report_problem_other'),
          value: 'SupportType.otherProblem',
        },
      ],
      dialog: false,
      loading: false,
      userInfo: {
        id: '',
        email: '',
        terms: '',
      },
      email: '',
      nip: '',
      phone: '',
      type: 'SupportType.problemWithPayment',
      problem: '',
    };
  },
  computed: {
    ...mapGetters({
      appName: 'branding/appName',
      appVersion: 'branding/appVersion',
      userInfoData: 'auth/getUser',
    }),
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.userInfo = {
          id: '',
          email: '',
          terms: '',
        };
        this.email = '';
        this.nip = '';
        this.phone = '';
        this.type = 'SupportType.problemWithPayment';
        this.problem = '';
      }
    },
  },
  methods: {
    save() {
      const user = this.userInfoData;

      this.loading = true;
      axios.post(
        '/api/users/support',
        {
          userInfo: {
            id: user.id,
            email: user.email,
            terms: user.terms_of_use_accepted,
          },
          phone: this.phone,
          type: this.type,
          platform: `www-${this.appName}`,
          version: this.appVersion,
          problem: this.problem,
        },
      )
        .then(() => {
          this.showToast('success', this.$i18n.t('alert.changes_saved'));
          this.loading = false;
          this.dialog = false;
        }, (error) => {
          if (error.response.status === 400) {
            if (!Number.isInteger(this.nip)) {
              this.showToast(
                'error',
                this.$i18n.t('alert.nip_number_incorrect'),
              );
            }
            this.loading = false;
          }
        });
    },
    submit() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) {
            this.save();
          }
        });
    },
    close() {
      this.dialog = false;
    },
    showToast(color, message) {
      this.snackbarColor = color;
      this.snackbarText = message;
      this.snackbar = true;
    },

  },
};
</script>

<style lang="stylus">
.dialog
  position: absolute

.dialog__content
  position: absolute
</style>
