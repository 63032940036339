<template>
  <div style="padding-left: 20px; padding-right: 20px;">
    <v-layout
      justify-end
      align-center
    >
      <v-row>
        <v-col
          cols="4"
        >
          <text-search
            v-model="search"
            :disabled="loading"
            @input="getDataDebounced"
          />
        </v-col>
        <v-col
          cols="4"
        >
          <date-range-picker
            key="dateRangeUsers"
            ref="dateRangeUsers"
            :show-presets="true"
            :show-custom="false"
            start-preset="currentMonth"
            @reload-transaction-list="onDateRangeChange"
          />
        </v-col>
        <v-col
          cols="4"
        >
          <v-btn
            color="primary"
            class="float-right bl-topUpButton mt-2"
            @click="$refs.InviteUserModal.dialog = true"
          >
            <v-icon>supervisor_account</v-icon>
            {{ $t('users.invite') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-layout>
    <div
      class="mt-15"
    >
      <invite-user-modal ref="InviteUserModal" />
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :options.sync="pagination"
      :loading="loading"
      :no-data-text="noDataTranslation"
      :single-expand="true"
      class="transparent"
    >
      <template #item="{ item, isExpanded, expand }">
        <tr
          :key="`user-item-row-${item.id}`"
        >
          <td
            class="text-sm-start"
            @click="expand(!isExpanded)"
          >
            <v-icon
              v-if="'a' !== item.status"
            >
              lock
            </v-icon>
            {{ item.email }}
          </td>
          <td
            class="text-center"
            @click="expand(!isExpanded)"
          >
            {{ $d(new Date(item.ctime.replace(' ', 'T')), 'long') }}
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.invitation_accepted"
              color="success"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              mdi-close-circle-outline
            </v-icon>
          </td>
          <td class="text-sm-end">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="!item.invitation_accepted"
                  class="mr-2"
                  elevation="1"
                  fab
                  tile
                  v-bind="attrs"
                  color="primary"
                  x-small
                  v-on="on"
                  @click.native="reinviteUser(item.id)"
                >
                  <v-icon color="white">
                    mdi-email-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('users.resend_invitation') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="'a' === item.status"
                  elevation="1"
                  tile
                  v-bind="attrs"
                  color="primary"
                  x-small
                  fab
                  v-on="on"
                  @click.native="lockUser(item.id)"
                >
                  <v-icon color="white">
                    lock
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('users.lock') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-show="'a' != item.status"
                  elevation="1"
                  fab
                  tile
                  v-bind="attrs"
                  color="primary"
                  x-small
                  v-on="on"
                  @click.native="unlockUser(item.id)"
                >
                  <v-icon color="white">
                    lock_open
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('users.unlock') }}</span>
            </v-tooltip>
          </td>
          <td class="text-sm-end">
            <div @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </div>
          </td>
        </tr>
      </template>
      <template #expanded-item="{ item }">
        <td
          v-if="!loading"
          :colspan="headers.length"
        >
          <stats-table
            v-if="item.stats !== null && item.stats.length > 0"
            :header-text="$t('users.stats')"
            :data="item.stats"
            :currency-code="item.currency.code ? item.currency.code : undefined"
          />
          <limit-table
            v-if="item.limit !== null && Object.keys(item.limit).length > 0"
            :header-text="$t('users.limits')"
            :currency-code="item.currency.code ? item.currency.code : undefined"
            :user-id="item.id"
            :data="item.limit"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import common from '@components/financial/common.js';
import axios from '@plugins/axios';
import StatsTable from '@components/users/StatsTable.vue';
import LimitTable from '@components/users/LimitTable.vue';
import InviteUserModal from '@components/users/InviteUserModal.vue';
import TextSearch from '@components/filters/TextSearch.vue';
import DateRangePicker from '@components/filters/DateRangePicker.vue';
import debounce from 'lodash/debounce';

export default {
  components: {
    TextSearch,
    StatsTable,
    LimitTable,
    InviteUserModal,
    DateRangePicker,
  },
  data() {
    return {
      loading: true,
      search: '',
      selected: [],
      from: null,
      to: null,
      pagination: {
        sortBy: ['email'],
        sortDesc: [true],
        itemsPerPage: 8,
      },
      icons: common.icons,
      headers: [
        {
          text: this.$i18n.t('email'),
          align: 'left',
          value: 'email',
        },
        {
          text: this.$i18n.t('table.create_date'),
          align: 'center',
          value: 'ctime',
        },
        {
          text: this.$i18n.t('table.invitation_accepted'),
          align: 'center',
          value: 'invitation_accepted',
        },
        {
          text: this.$i18n.t('actions.actions'),
          align: 'right',
          value: 'actions',
          sortable: false,
        },
        {
          text: '',
          align: 'right',
          value: 'expand',
          sortable: false,
        },
      ],
      noDataTranslation: this.$i18n.t('no_data'),
      items: [],
    };
  },
  computed: {
    pages() {
      return this.pagination && this.pagination.itemsPerPage
        ? Math.ceil(this.items.length / this.pagination.itemsPerPage) : 0;
    },
  },
  watch: {
    period() {
      this.getData();
    },
  },
  created() {
    this.getDataDebounced = debounce(() => {
      this.pagination.page = 1;
      this.getData();
    }, 1000);
    this.getData();
  },
  mounted() {
    this.getData();
    // create events hooks
    this.$eventHub.$on('user-list-refresh', this.getData);
  },
  beforeDestroy() {
    // destroy events hooks
    this.$eventHub.$off('user-list-refresh');
  },
  methods: {
    onDateRangeChange(values) {
      this.from = values.from;
      this.to = values.to;
      this.pagination.page = 1;
      this.getData();
    },
    getData() {
      if (this.$store.state.auth.user.fleet_manager === true) {
        this.loading = true;
        axios.get(
          '/api/users/fleet/users',
          {
            params: {
              search: this.search,
              dateFrom: this.from,
              dateTo: this.to,
            },
          },
        )
          .then((response) => {
            if (response.data) {
              this.items = response.data.items;
              this.loading = false;
            }
            this.loading = false;
          });
      }
    },
    reinviteUser(userId) {
      this.loading = true;
      axios.get(
        `/api/users/reinvite_user/${userId}`,
      )
        .then((response) => {
          if (response.data) {
            this.loading = false;
          }
        });
    },
    lockUser(userId) {
      this.loading = true;
      axios.post(
        `/api/users/fleet/users/${userId}`,
        {
          status: 'l',
        },
      )
        .then((response) => {
          if (response.data) {
            this.getData();
          }
        });
    },
    unlockUser(userId) {
      this.loading = true;
      axios.post(
        `/api/users/fleet/users/${userId}`,
        {
          status: 'a',
        },
      )
        .then((response) => {
          if (response.data) {
            this.getData();
          }
        });
    },
  },
};
</script>
